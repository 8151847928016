.layoutflow {
  flex-grow: 1;
  position: relative;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.react-flow{
	height: 75vh !important;
}

.datasource-node{
	width: 100px;
    padding: 10px;
    font-weight: 600;
    text-align: center;
    background-color: white;
    border: 1px solid #9E9E9E;
    min-width: 140px;
}

.add-btn{
	color: white;
    position: absolute;
    z-index: 1;
    right: -8px;
    top: 1px;
    padding: 5px;
    border-radius: 5px;
}

.activity-head{
	font-size: 14px;
	min-width: 140px;
    padding: 5px;
}
.filter{
  background-color: #2196F3;
  padding-top: 5px;
}